import React from "react"

import tick from "../../../images/UiUxDesign/Icons/UiUxAdvantages/tick.png"

import uiUxData from "../../../information/UiUxDesign/dataForUiUx"

import "./UiUxAdvantages.scss"

class UiUxAdvantages extends React.Component {
  renderSecondBlock = ({ id, desc }) => (
    <div key={id} className="uiux-advantages-text-block">
      <img className="" src={tick} />
      <div className="">{desc}</div>
    </div>
  )

  render() {
    return (
      <section className="uiux-advantages-parent container container-custom">
        <div className="uiux-advantages-flex">
          <div className="uiux-advantages-flex-item">
            <h3>
              Advantages <br /> of UI/UX design development
            </h3>
            <p>
              The user interface is a window for the world to experience your
              brand or product. A powerful user interface bridges the distance
              between the human brain and the digital product. Understanding
              human behaviour is key to unlocking the exceptional user
              experience. That is exactly why our approach to UI/UX is all about
              freedom, freedom of our customers to implement their ideas.
              Combine that with our technical expertise and compliance with the
              latest market trends and you have the best-selling recipe for your
              business.
            </p>
          </div>
          <div className="uiux-advantages-flex-item-right">
            {uiUxData.uiux_2nd.map(current => this.renderSecondBlock(current))}
          </div>
        </div>
      </section>
    )
  }
}
export default UiUxAdvantages
