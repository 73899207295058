import React from "react"
import FormField from "../FormField"

import "./ContactUsExpertise.scss"

export default function ContactUsExpertise({ url, form }) {
  return (
    <div className="subexpertise-contactus-container">
      <div className="subexpertise-contactus-wrapper">
        <div className="subexpertise-form-container">
          <FormField
            header="contact us"
            nameLabel="Your Name *"
            emailLabel="Email *"
            numberLabel="Your Phone Number *"
            messageLabel="Message"
            url={url}
            form={form}
          />
        </div>
      </div>
    </div>
  )
}
