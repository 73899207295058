import React from "react"

import { Link } from "gatsby"

import uxixData from "../../../information/UiUxDesign/dataForUiUx"

import "./UiUxUserExperience.scss"

class UiUxUserExperienceInterface extends React.Component {
  renderUiUxDetailList = ({ key, title, articles, className }) => (
    <div key={key} className={className}>
      <div className={title.className}>
        {title.label}
        <span className="fix-border-solution"></span>
      </div>
      <ul>
        {articles.labels.map(item => (
          <li key={item.key} className={articles.className}>
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  )

  render() {
    return (
      <section className="uiux-userExperience-parent">
        <div className="uiux-userExperience-container">
          <div className="uiux-userExperience-flex">
            {uxixData.uiUxDetailList.map(list =>
              this.renderUiUxDetailList(list)
            )}
          </div>
          <div className="design-btn">
            <Link to="/cases/design/" className="to-design">
              Design
            </Link>
          </div>
        </div>
      </section>
    )
  }
}
export default UiUxUserExperienceInterface
