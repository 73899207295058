import React from "react"

import artist from "../../../images/UiUxDesign/Graphic/UiUxHeader/artist.png"

import "./UiUxHeader.scss"

class UxuiHeader extends React.Component {
  render() {
    return (
      <section className="uiuxHeader-parent">
        <div className="uiuxHeader-child container">
          <h1>UI/UX Design Services</h1>
          <div>
            <img src={artist} alt="Robot artist" />
          </div>
        </div>
      </section>
    )
  }
}
export default UxuiHeader
