import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import UiUxHeader from "../../components/UiUxDesign/UiUxHeader"
import UiUxAdvantages from "../../components/UiUxDesign/UiUxAdvantages"
import UiUxUserExperienceInterface from "../../components/UiUxDesign/UiUxUserExperienceInterface"
import UiUxLatestProjects from "../../components/UiUxDesign/uiUxLatestProject"
import ContactUsExpertise from "../../components/CommonComponents/ContactUsExpertise"
import { mainUrl } from "../../js/config"

export default function UiUxDesign() {
  const sendUrl = typeof window !== "undefined" ? window?.location?.href : ""
  return (
    <div>
      <SEO
        title="UI/UX Design Agency: Design Creation and Implementation"
        description="A wide range of UI/UX design services from OSSystem UI/UX designers. Our 15 years of experience in promoting your business"
        canonical={`${mainUrl}/expertise/uiuxdesign/`}
      />
      <Layout showFormPoint={100}>
        <UiUxHeader />
        <UiUxAdvantages />
        <UiUxUserExperienceInterface />
        <UiUxLatestProjects />
        <ContactUsExpertise form={0} url={sendUrl} />
      </Layout>
    </div>
  )
}
